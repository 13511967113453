var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-2 box-shadow-0 border p-1",
    attrs: {
      "id": "reservation-list",
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "spinner-variant": "warning",
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('ReservationFilter', {
    attrs: {
      "search-text-filter": _vm.searchTextFilter,
      "booking-code-filter": _vm.bookingCodeFilter,
      "created-by-filter": _vm.createdByFilter,
      "agency-code-filter": _vm.agencyCodeFilter,
      "brand-filter": _vm.brandFilter,
      "source-filter": _vm.sourceFilter,
      "status-filter-by-user": _vm.statusFilterByUser,
      "start-date-filter": _vm.startDateFilter,
      "start-date-flight-filter": _vm.startDateFlightFilter,
      "end-date-flight-filter": _vm.endDateFlightFilter,
      "end-date-filter": _vm.endDateFilter,
      "status-options": _vm.optionsStatus,
      "brand-options": _vm.optionsAirLine,
      "source-options": _vm.optionsSource,
      "empty-filter": _vm.isEmptyFilter,
      "is-active-filter": _vm.isActiveFilter
    },
    on: {
      "update:createdByFilter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:created-by-filter": function updateCreatedByFilter($event) {
        _vm.createdByFilter = $event;
      },
      "update:agencyCodeFilter": function updateAgencyCodeFilter($event) {
        _vm.agencyCodeFilter = $event;
      },
      "update:agency-code-filter": function updateAgencyCodeFilter($event) {
        _vm.agencyCodeFilter = $event;
      },
      "update:brandFilter": function updateBrandFilter($event) {
        _vm.brandFilter = $event;
      },
      "update:brand-filter": function updateBrandFilter($event) {
        _vm.brandFilter = $event;
      },
      "update:sourceFilter": function updateSourceFilter($event) {
        _vm.sourceFilter = $event;
      },
      "update:source-filter": function updateSourceFilter($event) {
        _vm.sourceFilter = $event;
      },
      "update:statusFilterByUser": function updateStatusFilterByUser($event) {
        _vm.statusFilterByUser = $event;
      },
      "update:status-filter-by-user": function updateStatusFilterByUser($event) {
        _vm.statusFilterByUser = $event;
      },
      "update:startDateFilter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:start-date-filter": function updateStartDateFilter($event) {
        _vm.startDateFilter = $event;
      },
      "update:startDateFlightFilter": function updateStartDateFlightFilter($event) {
        _vm.startDateFlightFilter = $event;
      },
      "update:start-date-flight-filter": function updateStartDateFlightFilter($event) {
        _vm.startDateFlightFilter = $event;
      },
      "update:endDateFlightFilter": function updateEndDateFlightFilter($event) {
        _vm.endDateFlightFilter = $event;
      },
      "update:end-date-flight-filter": function updateEndDateFlightFilter($event) {
        _vm.endDateFlightFilter = $event;
      },
      "update:endDateFilter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:end-date-filter": function updateEndDateFilter($event) {
        _vm.endDateFilter = $event;
      },
      "update:isActiveFilter": function updateIsActiveFilter($event) {
        _vm.isActiveFilter = $event;
      },
      "update:is-active-filter": function updateIsActiveFilter($event) {
        _vm.isActiveFilter = $event;
      },
      "fetch-data": _vm.refetchData,
      "updateSearchTextFilter": function updateSearchTextFilter(value) {
        return _vm.updateSearchText(value);
      },
      "updateBookingCodeFilter": function updateBookingCodeFilter(value) {
        return _vm.updateBookingCode(value);
      },
      "reset": _vm.clearFilter
    }
  }), _c('b-table', {
    ref: "refReservationListTable",
    staticClass: "position-relative p-0 rounded",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchBookings,
      "striped": true,
      "hover": true,
      "borderless": true,
      "fields": _vm.tableColumns,
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "responsive": "",
      "no-border-collapse": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "small": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$te("reservation.".concat(data.label)) ? _vm.$t("reservation.".concat(data.label)) : data.label) + " ")])];
        }
      };
    }), {
      key: "row-details",
      fn: function fn(row) {
        return [row.item.roundtripBooking ? _c('b-card', {
          staticClass: "border-warning",
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-50",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" Chuyến khứ hồi: ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table', {
          staticClass: "position-relative p-0 rounded mb-0",
          attrs: {
            "striped": "",
            "thead-class": "d-none",
            "table-class": "",
            "borderless": "",
            "responsive": "",
            "no-border-collapse": "",
            "items": [row.item.roundtripBooking],
            "fields": _vm.tableColumns,
            "empty-text": _vm.$t('noMatchingResult'),
            "small": ""
          },
          scopedSlots: _vm._u([{
            key: "cell(index)",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-nowrap mr-3"
              })];
            },
            proxy: true
          }, {
            key: "cell(source)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-nowrap d-flex-center"
              }, [_c('span', {
                staticClass: "h5 mb-0 py-25 px-50",
                class: data.item.extra && data.item.extra.length && data.item.extra[0].contentSource ? "logo-".concat(data.item.extra[0].contentSource) : '',
                style: _vm.resolveBorderColorByAirline(data.item.source, 900)
              }, [_vm._v(" " + _vm._s(data.item.source) + " ")])])];
            }
          }, {
            key: "cell(bookingCode)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-center ml-2"
              }, [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-link', {
                staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
                attrs: {
                  "disabled": !_vm.canAccess('booking.detailBooking'),
                  "to": {
                    name: 'apps-reservations-modify',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]), _c('BButton', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.window.top",
                  value: _vm.$t('copy'),
                  expression: "$t('copy')",
                  modifiers: {
                    "hover": true,
                    "window": true,
                    "top": true
                  }
                }],
                staticClass: "p-50 rounded-circle ml-25",
                attrs: {
                  "variant": "flat-primary",
                  "disabled": _vm.disabledCopy
                },
                on: {
                  "click": function click($event) {
                    return _vm.copyPnr(data.item.bookingCode);
                  }
                }
              }, [_c('IAmIcon', {
                attrs: {
                  "icon": "copy",
                  "size": "18",
                  "color": "#2FA4E5"
                }
              })], 1)], 1), data.item.roundtripBooking ? _c('b-button', {
                staticClass: "font-medium-2 py-25 px-50 font-weight-bold",
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                },
                on: {
                  "click": data.toggleDetails
                }
              }, [_vm._v(" " + _vm._s(data.item.roundtripBooking.bookingCode) + " ")]) : _vm._e()], 1)];
            }
          }, {
            key: "cell(status)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: " ml-3"
              }, [_c('b-badge', {
                class: "badge-glow round text-uppercase",
                attrs: {
                  "variant": "light-".concat(_vm.resolveBookingStatusVariant(data.item.status || data.item.bookingStatus))
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.item.status))) + " ")]), _c('div', [data.item.timeHold && !data.item.status.toUpperCase().includes('PAID') ? _c('small', [_vm._v(" (" + _vm._s(_vm.convertISODateTime(data.item.timeHold).time) + " " + _vm._s(_vm.convertISODateTime(data.item.timeHold).date) + ") ")]) : _vm._e()])], 1)];
            }
          }, {
            key: "cell(passenger)",
            fn: function fn(data) {
              return _vm._l(data.item.paxLists, function (pax, index) {
                return _c('div', {
                  key: index,
                  staticClass: "ml-2"
                }, [_c('small', {
                  staticClass: "text-body-3 text-nowrap"
                }, [_vm._v(" " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")])]);
              });
            }
          }, {
            key: "cell(booker)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "w-100 h-100",
                attrs: {
                  "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
                }
              }, [_c('small', {
                staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
              }, [_vm._v(" " + _vm._s(data.item.createdBy.username) + " ")]), _c('b-tooltip', {
                attrs: {
                  "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
                  "triggers": "hover focus",
                  "placement": "top",
                  "boundary": "viewport",
                  "variant": "info"
                }
              }, [_c('div', [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")]), _c('div', [_vm._v(" " + _vm._s(data.item.createdBy.employeeCode) + " ")])])], 1)];
            }
          }, {
            key: "cell(flight)",
            fn: function fn(data) {
              return [data.item.bookingRequest ? _c('div', _vm._l(_vm.resolveBookingRequest(data.item.bookingRequest.itineraries), function (trip, index) {
                return _c('div', {
                  key: index,
                  staticClass: "text-nowrap text-body-3"
                }, _vm._l(trip.segments, function (segment, indexSegment) {
                  return _c('div', {
                    key: indexSegment,
                    staticClass: "d-flex flex-nowrap my-50"
                  }, [segment.airline ? _c('IAmLogoAirline', {
                    attrs: {
                      "airline": segment.operating === 'BL' ? segment.operating : segment.airline.split(' | ')[0],
                      "size": "xs",
                      "custom-class": "mr-50"
                    }
                  }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(segment.devSegmentIndex) + " " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline, segment.flightNumber))) + " " + _vm._s(segment.bookingClass) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).dayMonth) + " " + _vm._s("".concat(segment.departure).concat(segment.arrival)) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalTime).hourMin) + " ")])], 1);
                }), 0);
              }), 0) : _c('div', _vm._l(data.item.itineraries.filter(function (trip) {
                return trip.some(function (segment) {
                  return segment.status !== 'HX';
                });
              }), function (trip, index) {
                return _c('div', {
                  key: index,
                  staticClass: "text-nowrap text-body-3"
                }, _vm._l(trip, function (segment, indexSegment) {
                  return _c('div', {
                    key: indexSegment,
                    staticClass: "d-flex flex-nowrap my-50"
                  }, [segment.airline ? _c('IAmLogoAirline', {
                    attrs: {
                      "airline": segment.operating === 'BL' ? segment.operating : segment.airline.split(' | ')[0],
                      "size": "xs",
                      "custom-class": "mr-50"
                    }
                  }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])], 1);
                }), 0);
              }), 0)];
            }
          }, {
            key: "cell(rebook)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex justify-content-center flex-nowrap"
              }, [_vm.checkCanRebook(data.item) ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-warning.window",
                  modifiers: {
                    "hover": true,
                    "v-warning": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon p-50",
                attrs: {
                  "id": "btn-rebook-".concat(data.item.id),
                  "variant": "outline-warning",
                  "title": _vm.isMobileView ? '' : 'Rebook'
                },
                on: {
                  "click": function click($event) {
                    return _vm.showRebookModal(data.item);
                  }
                }
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "RotateCwIcon",
                  "size": "22"
                }
              })], 1) : _vm._e()], 1)];
            }
          }, {
            key: "cell(action)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex flex-nowrap"
              }, [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 p-50",
                attrs: {
                  "variant": "outline-info",
                  "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportTicket')
                },
                on: {
                  "click": function click($event) {
                    return _vm.showPopupTicket(data.item);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/plane-ticket-blue.svg")
                }
              })]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }, {
                  name: "b-modal",
                  rawName: "v-b-modal.reservation-modify-change-note-modal",
                  modifiers: {
                    "reservation-modify-change-note-modal": true
                  }
                }],
                staticClass: "btn-icon mr-1 p-50",
                attrs: {
                  "id": "button-change-note",
                  "title": _vm.isMobileView ? '' : 'Chỉnh sửa ghi chú',
                  "variant": "outline-primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editNote(data.item, $event.target);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/edit.svg")
                }
              })]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 p-50",
                attrs: {
                  "variant": "outline-info",
                  "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportSms')
                },
                on: {
                  "click": function click($event) {
                    return _vm.showPopupSms(data.item);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/mail.svg")
                }
              })])], 1)];
            }
          }, {
            key: "cell(createdAt)",
            fn: function fn(data) {
              return [_c('b-media', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-media-body', [_c('h6', {
                staticClass: "mb-0"
              }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).time) + " ")]), _c('h6', {
                staticClass: "mb-0"
              }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).date) + " ")])])], 1)];
            }
          }, {
            key: "cell(note)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "two-line-ellipsis"
              }, [_vm._v(" " + _vm._s(data.item.note) + " ")])];
            }
          }], null, true)
        })], 1) : _vm._e(), row.item.multiCityBookings && !_vm.isEmpty(row.item.multiCityBookings) ? _c('b-card', {
          staticClass: "border-warning",
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-50",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" Danh sách vé: ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table', {
          staticClass: "p-0 rounded mb-0",
          attrs: {
            "striped": "",
            "thead-class": "d-none",
            "table-class": "",
            "borderless": "",
            "no-border-collapse": "",
            "items": row.item.multiCityBookings,
            "fields": ['bookingCode'],
            "empty-text": _vm.$t('noMatchingResult'),
            "small": ""
          },
          scopedSlots: _vm._u([{
            key: "cell(bookingCode)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  "margin-left": "150px"
                }
              }, [data.item.id ? _c('b-link', {
                staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
                attrs: {
                  "disabled": !_vm.canAccess('booking.detailBooking'),
                  "to": {
                    name: 'apps-reservations-modify',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]) : _c('div', {
                staticClass: "font-weight-bold d-block text-nowrap text-info pb-0"
              }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]), _c('BButton', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.window.top",
                  value: _vm.$t('copy'),
                  expression: "$t('copy')",
                  modifiers: {
                    "hover": true,
                    "window": true,
                    "top": true
                  }
                }],
                staticClass: "p-50 rounded-circle ml-25",
                attrs: {
                  "variant": "flat-primary",
                  "disabled": _vm.disabledCopy
                },
                on: {
                  "click": function click($event) {
                    return _vm.copyPnr(data.item.bookingCode);
                  }
                }
              }, [_c('IAmIcon', {
                attrs: {
                  "icon": "copy",
                  "size": "18",
                  "color": "#2FA4E5"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), !_vm.isEmpty(row.item.crossReferenceDetails) ? _c('b-card', {
          staticClass: "border-warning",
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-50",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" Số vé liên quan: ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table', {
          staticClass: "p-0 rounded mb-0",
          attrs: {
            "striped": "",
            "thead-class": "d-none",
            "table-class": "",
            "borderless": "",
            "no-border-collapse": "",
            "items": row.item.crossReferenceDetails,
            "fields": ['bookingCode'],
            "empty-text": _vm.$t('noMatchingResult'),
            "small": ""
          },
          scopedSlots: _vm._u([{
            key: "cell(bookingCode)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  "margin-left": "150px"
                }
              }, [data.item.id ? _c('b-link', {
                staticClass: "font-weight-bold d-block text-nowrap text-info pb-0",
                attrs: {
                  "disabled": !_vm.canAccess('booking.detailBooking'),
                  "to": {
                    name: 'apps-reservations-modify',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              }, [_vm._v(" " + _vm._s(data.item.pnrNumber) + " ")]) : _c('div', {
                staticClass: "font-weight-bold d-block text-nowrap text-info pb-0"
              }, [_vm._v(" " + _vm._s(data.item.pnrNumber) + " ")]), _c('BButton', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.window.top",
                  value: _vm.$t('copy'),
                  expression: "$t('copy')",
                  modifiers: {
                    "hover": true,
                    "window": true,
                    "top": true
                  }
                }],
                staticClass: "p-50 rounded-circle ml-25",
                attrs: {
                  "variant": "flat-primary",
                  "disabled": _vm.disabledCopy
                },
                on: {
                  "click": function click($event) {
                    return _vm.copyPnr(data.item.pnrNumber);
                  }
                }
              }, [_c('IAmIcon', {
                attrs: {
                  "icon": "copy",
                  "size": "18",
                  "color": "#2FA4E5"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), row.item.lowFareTrackingTasks && row.item.lowFareTrackingTasks.some(function (i) {
          return i.result;
        }) ? _c('b-card', {
          staticClass: "border-warning",
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-50",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" Danh sách mã đặt chỗ từ canh vé thành công: ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-table', {
          staticClass: "p-0 rounded mb-0",
          attrs: {
            "striped": "",
            "thead-class": "d-none",
            "table-class": "",
            "borderless": "",
            "no-border-collapse": "",
            "items": row.item.lowFareTrackingTasks.filter(function (vr) {
              return vr.result;
            }).map(function (task) {
              return JSON.parse(task.result).lowFareTrackedBookings.map(function (ve) {
                return Object.assign({}, ve);
              });
            }),
            "fields": ['bookingCode'],
            "empty-text": _vm.$t('noMatchingResult'),
            "small": ""
          },
          scopedSlots: _vm._u([{
            key: "cell(bookingCode)",
            fn: function fn(_ref) {
              var item = _ref.item;
              return _vm._l(item, function (datcho, mIndex) {
                return _c('div', {
                  key: mIndex,
                  staticStyle: {
                    "margin-left": "150px"
                  }
                }, [_c('b-link', {
                  staticClass: "font-weight-bold d-block text-nowrap text-info py-50",
                  attrs: {
                    "disabled": !_vm.canAccess('booking.detailBooking'),
                    "to": {
                      name: 'apps-reservations-modify',
                      params: {
                        id: datcho.id
                      }
                    }
                  }
                }, [_c('div', [_vm._v(" " + _vm._s(datcho.pnr) + " ")])])], 1);
              });
            }
          }], null, true)
        })], 1) : _vm._e(), row.item.lowFareTrackingFrom ? _c('b-card', {
          staticClass: "border-warning",
          attrs: {
            "header-bg-variant": "light-info",
            "header-class": "py-50",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', [_c('span', {
                staticClass: "text-airline font-weight-bolder"
              }, [_vm._v(" Mã đặt chỗ đã thực hiện canh vé này: ")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info py-50",
          staticStyle: {
            "margin-left": "150px"
          },
          attrs: {
            "disabled": !_vm.canAccess('booking.detailBooking'),
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: row.item.lowFareTrackingFrom.bookingId
              }
            }
          }
        }, [_c('div', [_vm._v(" " + _vm._s(row.item.lowFareTrackingFrom.bookingCode) + " ")])])], 1) : _vm._e()];
      }
    }, {
      key: "cell(index)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold d-flex-center",
          staticStyle: {
            "color": "#333333"
          }
        }, [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.sizePerPage + data.index + 1) + " ")])])];
      }
    }, {
      key: "cell(source)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap d-flex-center"
        }, [_c('span', {
          staticClass: "h5 mb-0 py-25 px-50 d-flex-center gap-1",
          style: _vm.resolveBorderColorByAirline(data.item.source, 900)
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(data.item.source.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(data.item.source.toUpperCase())) : data.item.source.toUpperCase()) + " "), data.item.extra && data.item.extra.length && data.item.extra[0].contentSource ? _c('span', {
          class: "bagde-".concat(data.item.extra[0].contentSource)
        }, [_vm._v(" " + _vm._s(data.item.extra[0].contentSource) + " ")]) : _vm._e()])])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-1"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "disabled": !_vm.canAccess('booking.detailBooking'),
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.bookingCode) + " ")]), data.item.isEnableAutoIssue ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger.window.top",
            value: _vm.$t('reservation.lockBalance'),
            expression: "$t('reservation.lockBalance')",
            modifiers: {
              "hover": true,
              "v-danger": true,
              "window": true,
              "top": true
            }
          }],
          staticClass: "btn btn-flat-danger p-25 rounded-circle"
        }, [_c('IAmIcon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "infoOutline",
            "size": "20"
          }
        })], 1) : _vm._e(), _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window.top",
            value: _vm.$t('copy'),
            expression: "$t('copy')",
            modifiers: {
              "hover": true,
              "window": true,
              "top": true
            }
          }],
          staticClass: "p-50 rounded-circle ml-25",
          attrs: {
            "variant": "flat-primary",
            "disabled": _vm.disabledCopy
          },
          on: {
            "click": function click($event) {
              return _vm.copyPnr(data.item.bookingCode);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "copy",
            "size": "18",
            "color": "#2FA4E5"
          }
        })], 1)], 1), data.item.roundtripBooking || !_vm.isEmpty(data.item.crossReferenceDetails) || data.item.lowFareTrackingTasks && data.item.lowFareTrackingTasks.some(function (i) {
          return i.result;
        }) || data.item.lowFareTrackingFrom || data.item.multiCityBookings && !_vm.isEmpty(data.item.multiCityBookings) ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-warning.window.right",
            modifiers: {
              "hover": true,
              "v-warning": true,
              "window": true,
              "right": true
            }
          }],
          staticClass: "font-medium-1 py-25 d-flex",
          class: data.item.roundtripBooking || !_vm.isEmpty(data.item.multiCityBookings) ? 'px-0' : 'px-2',
          attrs: {
            "title": _vm.isMobileView ? '' : data.item.roundtripBooking ? 'Chuyến khứ hồi' : !_vm.isEmpty(data.item.multiCityBookings) && data.item.multiCityBookings[0].isBookingGroup ? 'Vé đoàn' : 'Vé tách chặng',
            "size": "sm",
            "variant": data.item.roundtripBooking ? 'flat-warning' : 'outline-warning'
          },
          on: {
            "click": data.toggleDetails
          }
        }, [data.item.roundtripBooking ? _c('span', {
          staticClass: "mr-25 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.roundtripBooking.bookingCode) + " ")]) : !_vm.isEmpty(data.item.multiCityBookings) && data.item.multiCityBookings[0].isBookingGroup ? _c('span', {
          staticClass: "mr-25 text-nowrap px-25"
        }, [_vm._v(" Vé đoàn ")]) : !_vm.isEmpty(data.item.multiCityBookings) && !data.item.multiCityBookings[0].isBookingGroup && (data.item.multiCityBookings[0].splitItineraries || data.item.multiCityBookings[0].flightType) ? _c('span', {
          staticClass: "mr-25 text-nowrap px-25"
        }, [_vm._v(" Vé tách chặng ")]) : _vm._e(), data.detailsShowing ? _c('feather-icon', {
          attrs: {
            "icon": "ChevronUpIcon",
            "size": "16"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "16"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        var _data$item, _data$item2, _data$item3, _data$item4;
        return [_c('b-badge', {
          class: "badge-glow round text-uppercase",
          attrs: {
            "variant": "light-".concat(_vm.resolveBookingStatusVariant(((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.status) === 'PARTIALLY_PAID' ? 'PAID' : data.item.status || data.item.bookingStatus))
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : _data$item2.status) === 'PARTIALLY_PAID' ? 'PAID' : data.item.status || data.item.bookingStatus))) + " ")]), ((_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : _data$item3.status) === 'PARTIALLY_PAID' ? _c('b-badge', {
          class: "badge-glow round text-uppercase mt-50",
          attrs: {
            "variant": "light-".concat(_vm.resolveBookingStatusVariant(data.item.status || data.item.bookingStatus))
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(((_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : _data$item4.status) === 'PARTIALLY_PAID' ? 'PARTIALLY_PAID_ONLY' : data.item.status || data.item.bookingStatus))) + " ")]) : _vm._e(), _c('div', [data.item.timeHold && !data.item.status.toUpperCase().includes('PAID') ? _c('small', [_vm._v(" (" + _vm._s(_vm.convertISODateTime(data.item.timeHold).time) + " " + _vm._s(_vm.convertISODateTime(data.item.timeHold).date) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "cell(passenger)",
      fn: function fn(data) {
        return _vm._l(data.item.paxLists, function (pax, index) {
          return _c('div', {
            key: index
          }, [_c('small', {
            staticClass: "text-body-3 text-nowrap"
          }, [_vm._v(" " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")])]);
        });
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex-center flex-column",
          attrs: {
            "id": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt)
          }
        }, [_c('div', {
          staticClass: "text-info font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.username.toUpperCase()) + " ")]), _c('div', {
          staticClass: "text-dark font-weight-bold text-body-3 text-nowrap"
        }, [_vm._v(" (" + _vm._s(_vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase() : data.item.createdBy.agency.agencyCode.toUpperCase()) + ") ")]), _c('b-tooltip', {
          attrs: {
            "target": "booker-".concat(data.item.createdBy.id, "-").concat(data.item.createdAt),
            "triggers": "hover",
            "placement": "top",
            "boundary": "viewport",
            "variant": "dark"
          }
        }, [_c('BRow', [_c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(data.item.createdBy.lastName) + " " + _vm._s(data.item.createdBy.firstName) + " (" + _vm._s(data.item.createdBy.type) + ") ")])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã tài khoản: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.employeeCode.toUpperCase()))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.agencyName))])]), _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã đại lý: ")]), _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.agencyCode.toUpperCase()))])]), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Tên ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.parentAgency.agencyName))])]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          staticClass: "text-nowrap",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" Mã ĐL chủ quản: ")]) : _vm._e(), _vm.isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000 ? _c('BCol', {
          attrs: {
            "cols": "8"
          }
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase()))])]) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "cell(flight)",
      fn: function fn(data) {
        return [data.item.bookingRequest ? _c('div', _vm._l(_vm.resolveBookingRequest(data.item.bookingRequest.itineraries), function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip.segments, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline.split(' | ')[0],
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(segment.devSegmentIndex) + " " + _vm._s("".concat(_vm.resolveAirlineFlightNumber(segment.airline.split(' | ')[0], segment.flightNumber))) + " " + _vm._s(segment.bookingClass) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).dayMonth) + " " + _vm._s("".concat(segment.departure).concat(segment.arrival)) + " " + _vm._s(_vm.convertISODateTime(segment.departureTime).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalTime).hourMin) + " ")])], 1);
          }), 0);
        }), 0) : _c('div', _vm._l(data.item.itineraries.filter(function (trip) {
          return trip.some(function (segment) {
            return segment.status !== 'HX';
          });
        }), function (trip, index) {
          return _c('div', {
            key: index,
            staticClass: "text-nowrap text-body-3"
          }, _vm._l(trip, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex flex-nowrap my-50"
            }, [segment.airline ? _c('IAmLogoAirline', {
              attrs: {
                "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
                "size": "xs",
                "rounded": "",
                "custom-class": "mr-50"
              }
            }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])], 1);
          }), 0);
        }), 0)];
      }
    }, {
      key: "cell(rebook)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex justify-content-center flex-nowrap"
        }, [_vm.checkCanRebook(data.item) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-warning.window",
            modifiers: {
              "hover": true,
              "v-warning": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "id": "btn-rebook-".concat(data.item.id),
            "variant": "outline-warning",
            "title": _vm.isMobileView ? '' : 'Rebook'
          },
          on: {
            "click": function click($event) {
              return _vm.showRebookModal(data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "RotateCwIcon",
            "size": "22"
          }
        })], 1) : data.item.status === 'DRAFT' ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-warning.window",
            modifiers: {
              "hover": true,
              "v-warning": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "id": "btn-submit-booking-".concat(data.item.id),
            "variant": "outline-primary",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.submitBooking')
          },
          on: {
            "click": function click($event) {
              return _vm.showSubmitBookingModal(data.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ShareIcon",
            "size": "22"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(priceNet)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder",
          staticStyle: {
            "color": "#00558f"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.totalAmountToBePaid + data.item.totalAmountPaid || 0)) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right font-weight-bolder",
          staticStyle: {
            "color": "#00558f"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.totalAmountTicket || 0)) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center flex-nowrap gap-1"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "light",
            "title": _vm.isMobileView ? '' : data.item.isActive ? _vm.$t('reservation.hideBooking') : _vm.$t('reservation.showBooking')
          },
          on: {
            "click": function click($event) {
              return _vm.showConfirmToggleHideBooking(data.item, !data.item.isActive);
            }
          }
        }, [data.item.isActive ? _c('feather-icon', {
          staticStyle: {
            "color": "#efad02"
          },
          attrs: {
            "icon": "EyeIcon",
            "size": "18"
          }
        }) : _c('feather-icon', {
          staticStyle: {
            "color": "#efad02"
          },
          attrs: {
            "icon": "EyeOffIcon",
            "size": "18"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "light",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportTicket')
          },
          on: {
            "click": function click($event) {
              return _vm.showPopupTicket(data.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/plane-ticket-blue.svg")
          }
        })]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }, {
            name: "b-modal",
            rawName: "v-b-modal.reservation-modify-change-note-modal",
            modifiers: {
              "reservation-modify-change-note-modal": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "id": "button-change-note",
            "title": _vm.isMobileView ? '' : 'Chỉnh sửa ghi chú',
            "variant": "light"
          },
          on: {
            "click": function click($event) {
              return _vm.editNote(data.item, $event.target);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/edit.svg")
          }
        })]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "light",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.exportSms')
          },
          on: {
            "click": function click($event) {
              return _vm.showPopupSms(data.item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/SMS.svg")
          }
        })]), _vm.isF3canSendMailAirline && data.item.status !== 'CANCEL' && _vm.canAccess('booking.sendEmail') && !data.item.isOutside ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "light",
            "title": _vm.isMobileView ? '' : _vm.$t('reservation.sendEmail.title')
          },
          on: {
            "click": function click(e) {
              return _vm.handleShowModalSendEmail(data.item, e);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@icons/mail.svg")
          }
        })]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-body', [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).time) + " ")]), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.dateCreate).date) + " ")])])], 1)];
      }
    }, {
      key: "cell(note)",
      fn: function fn(data) {
        var _vm$checkLowFareTrack, _vm$checkLowFareTrack2, _vm$checkLowFareTrack3;
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window.top",
            value: "".concat(data.item.autoIssueTime ? "Th\u1EDDi gian xu\u1EA5t v\xE9 t\u1EF1 \u0111\u1ED9ng: ".concat(_vm.convertISODateTime(data.item.autoIssueTime).dateTime) : '', " ").concat((_vm$checkLowFareTrack = _vm.checkLowFareTracking(data.item)) !== null && _vm$checkLowFareTrack !== void 0 && _vm$checkLowFareTrack.status ? (_vm$checkLowFareTrack2 = _vm.checkLowFareTracking(data.item)) === null || _vm$checkLowFareTrack2 === void 0 ? void 0 : _vm$checkLowFareTrack2.toString : ''),
            expression: "`${\n            data.item.autoIssueTime\n              ? `Thời gian xuất vé tự động: ${convertISODateTime(data.item.autoIssueTime).dateTime}`\n              : ''\n          } ${ checkLowFareTracking(data.item)?.status ? checkLowFareTracking(data.item)?.toString : '' }`",
            modifiers: {
              "hover": true,
              "window": true,
              "top": true
            }
          }],
          staticClass: "two-line-ellipsis"
        }, [_vm._v(" " + _vm._s(data.item.note) + " " + _vm._s(data.item.autoIssueTime ? "Th\u1EDDi gian xu\u1EA5t v\xE9 t\u1EF1 \u0111\u1ED9ng: ".concat(_vm.convertISODateTime(data.item.autoIssueTime).dateTime) : "") + " " + _vm._s(((_vm$checkLowFareTrack3 = _vm.checkLowFareTracking(data.item)) === null || _vm$checkLowFareTrack3 === void 0 ? void 0 : _vm$checkLowFareTrack3.status) || '') + " ")])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex-center justify-content-md-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " "), _c('b-dropdown', {
    staticClass: "per-page-selector d-inline-block p-0 mx-25",
    attrs: {
      "variant": "warning",
      "text": "".concat(_vm.sizePerPage)
    },
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, _vm._l(_vm.sizePerPageLgOptions, function (item) {
    return _c('b-dropdown-item', {
      key: item,
      on: {
        "click": function click($event) {
          _vm.sizePerPage = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _vm._v(" " + _vm._s(_vm.$t('tickets')) + " "), _c('div', [_vm._v(_vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('tickets')))])], 1)]), _c('b-col', {
    staticClass: "d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "pagination-warning mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalReservations,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('NoteModal', {
    attrs: {
      "reservation-data": _vm.reservationData
    },
    on: {
      "update:reservationData": function updateReservationData($event) {
        _vm.reservationData = $event;
      },
      "update:reservation-data": function updateReservationData($event) {
        _vm.reservationData = $event;
      },
      "refetch-data": _vm.refetchData
    }
  }), _vm.ticketData ? _c('ModalSms', {
    attrs: {
      "ticket-data": _vm.ticketData
    }
  }) : _vm._e(), _vm.ticketData ? _c('ReBookModal', {
    attrs: {
      "booking-data": _vm.ticketData
    },
    on: {
      "refetch-data": _vm.refetchData
    }
  }) : _vm._e(), _c('SendEmailModal'), _vm.ticketData ? _c('ModalSubmitDraft', {
    attrs: {
      "booking-data": _vm.ticketData
    },
    on: {
      "refetch-booking-data": _vm.refetchData
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }